<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="page-header">
        <h4 class="page-title">Clients</h4>
      </div>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body nw-opd">
              <div class="row me-1">
                 <div class="col-lg-4">
                    <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchtext" id="searchtext" autocomplete="off" v-on:keyup="getClientList(1)">
                 </div>
                 <div class="col-lg-1">
                 </div>
                    <date-range-picker
                                class="col-lg-3 mb-lg-0 mb-2"
                                ref="picker"
                                :max-date="MAXDATE"
                                :opens="center"
                                :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
                                :single-date-picker="false"
                                :showDropdowns="showDropdowns"
                                :show-week-numbers= "false"
                                :time-picker="false"
                                :autoApply="true"
                                v-model="dateRange"
                                :linked-calendars="true"
                                :dateFormat="dd-mmm-yyyy"
                                @finish-selection=getOpdList(1)
                                @update=getClientList(1)
                        />
                <div class="col-lg-2">
                    <multiselect
                        v-model="filter_status"
                        :options="status_list"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true" placeholder="Status"
                        :preselect-first="true"
                        :showLabels="false"
                        :allowEmpty="false"
                        @input=getClientList(1)
                    /></multiselect>
                </div>
                <router-link to="/master-admin/add-client" type="button" class="col-lg-2 btn btn-gradient-primary">Add Client</router-link>
              </div>
              <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Business Name</th>
                        <th>Owner Name</th>
                        <th>Email Address</th>
                        <th>Mobile No.</th>
                        <th>Location</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(obj,i) in client_list" :key="i">
                        <td>{{i+1}}</td>
                        <td>{{obj.business_name}}</td>
                        <td>{{obj.name}}</td>
                        <td>{{obj.email}}</td>
                        <td>{{obj.mobile}}</td>
                        <td>{{obj.city}} - {{obj.state}} </br> {{obj.country}} - {{obj.postal_code}} </br></td>
                        <td><label v-bind:class="{'badge badge-success': obj.status === 'Active',
                                           'badge badge-danger': obj.status === 'InActive' ||
                                           obj.status === 'Inactive'}"
                                           >{{obj.status}}</label></td>
                        <td>
                            <router-link :to="{name: 'master-admin-client-details',params: { id: obj.id}}" type="button" >
                                <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-eye"></i></button></router-link>
                            &nbsp&nbsp
                            <!--<button type="button" @click="deleteSubscriptionPlan(obj.id, obj.plan_name, i)"class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button></router-link>-->
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && client_list.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted">{{ errorMessage }}</small>
                    <br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                <ul id="border-pagination">
                  <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                  <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getClientList(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                  <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBarMaster.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBarMaster.vue";
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from "vuex";
import string from "../../../constant/strings.js"
import Swal from 'sweetalert2'
import DateRangePicker from 'vue2-daterange-picker'
import moment from "moment";

export default {
  name:'clients',
  title: string.PAGE_TITLE_CLIENTS,
  components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect,
      DateRangePicker,
  },
  data() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const maxDate = new Date(today)

    return {
      client_list:[],
      is_API_call_running: false,
      listPages:0,
      activePage:1,
      searchtext:'',
      myTimer:null,
      errorMessage : 'No Plans Found.',
      status_list:['All','Active', 'Inactive'],
      status_auto_renewal_list:[{"id":"All", "name":"All"}, {"id":"1", "name":"Yes"}, {"id":"0", "name":"No"}],
      filter_status: "All",
      filter_auto_renewal: {"id":"All", "name":"All"},
      dateRange:{
        startDate: today,
        endDate: today
      },
      MAXDATE:maxDate,
    }
  },
  mounted() {
    this.getClientList(this.activePage);
  },
  methods:{
    ...mapActions("hospital",["getClientListAPI", "updateSubscriptionPlanStatusAPI", "deleteSubscriptionPlanAPI"]),

    getClientList(page){
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
        this.client_list = [];
        var bodyFormData = new FormData();
        bodyFormData.append('filter_value', this.searchtext);
        bodyFormData.append('page', page);
        bodyFormData.append('filter_status', this.filter_status);
        bodyFormData.append('filter_from_date', moment(String(this.dateRange.startDate)).format('YYYY-MM-DD'));
        bodyFormData.append('filter_to_date', moment(String(this.dateRange.endDate)).format('YYYY-MM-DD'));

        this.getClientListAPI(bodyFormData).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
            this.client_list = response.data;
            this.listPages = response.no_of_pages;
            }else{
              this.errorMessage = response.message;
            }
        });
      }, 500)
    },

    updateSubscriptionPlanStatus(plan_id, obj_status, index){
      var bodyFormData = new FormData();
      bodyFormData.append('plan_id', plan_id);
      if(obj_status == "Active") {
        bodyFormData.append('status', "Inactive");
      } else {
        bodyFormData.append('status', "Active");
      }
      this.updateSubscriptionPlanStatusAPI(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.client_list.splice(index, 1, response.data);
            }else{
                this.errorMessage = response.message;
            }
      });
    },

    previewClick(){
          this.activePage--;
          if(this.activePage >= 1){
            this.getClientList(this.activePage);
          }else{
            this.activePage = 1;
          }
        },

    nextClick(){
       this.activePage++;
       if(this.activePage <= this.listPages){
            this.getClientList(this.activePage);
       }else{
            this.activePage = this.listPages;
       }
    },
    getAutoRenewalText(is_auto_renewal) {
        if(is_auto_renewal) {
            return "Yes"
        } else {
            return "No"
        }
    },

    deleteSubscriptionPlan(id, plan_name, i) {
        Swal.fire({
          title: string.LABEL_ARE_YOU_SURE,
          text: string.ALERT_MESSAGE_DELETE_PRE + plan_name + string.SUBSCRIPTION_PLAN,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            bodyFormData.append('plan_id', id);
            this.deleteSubscriptionPlanAPI(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    for (let i = 0; i <= this.client_list.length;i++){
                        if(id == this.client_list[i].id) {
                            this.client_list.splice(i, 1);
                            break;
                        }
                    }
                } else{
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
        });
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>